.configured-notification {
  border-radius: 4px;
  .MuiTypography-root {
    font-size: 0.9rem;
  }
}

.configured-box-second {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5rem;
  width: 60%;
}

.no-notifications-configured {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 4rem 1rem;

  .MuiTypography-root {
      font-size: 0.8rem;
      color: #9c9c9c;
  }
}