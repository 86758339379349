html {
    overflow-y: hidden;
    scroll-behavior: smooth;
}

// ::-webkit-scrollbar {
//     width: 0;
//     height: 0;
// }

/* width */
::-webkit-scrollbar {
    // width: 10px;
    width: 8px;
    // background-color: #1C2125;
    background-color: #272E36;
    border-radius: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #272E36;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #535353;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #535353;
  }
  