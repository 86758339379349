::-webkit-file-upload-button {
    display: none;
}

.customFile {
    margin-bottom: 0 !important;

    input[type="file"] {
        width: 100%;
        padding: 1rem;
    }

    .iconButton {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .customLabel {
        width: 100%;
        flex-grow: 1;
        text-align: center;
        color: #00B89F !important;
    }
}

.deleteIcon {
    width: 1.1rem;
    height: 1.1rem;
}

.txtError {
    color: #ff3b3b !important;
}