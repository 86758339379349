.edit-senior {
    .spcl {
        height: 5rem !important;
    }

    @media screen and (min-width: 1200px) {
        .spcl {
            height: 5rem !important;
        }
    }

    @media screen and (min-width: 768px) {
        .spcl {
            height: 5rem !important;
        }
    }

    @media screen and (min-width: 425px) {
        .spcl {
            height: 5rem !important;
        }
    }

    @media screen and (min-width: 320px) {
        .spcl {
            height: 5rem !important;
        }
    }
}

.edit-senior-dialog-box {
    .MuiDialog-paper {
        max-width: 100% !important;
        width: 80% !important;
    }
}