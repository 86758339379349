.Mui-error {
  asterisk {
    color: "#db3131",
    "&$error" {
      color: "#db3131",
    }
  }
  color: black !important;
}

.MuiCalendarPicker-root {
  svg {
    path {
      fill: #fff;
    }
  }
  .MuiPickersCalendarHeader-label {
    font-size: 0.8rem;
  }
}