.active {
  color: #00b89f !important;

  path {
    fill: #00b89f !important;
  }

  #Path_859 {
    fill: none !important;
  }

  #Path_860 {
    fill: #00b89f !important;
  }

  #Medic_Statistic {
    rect {
      stroke: #00b89f !important;
      fill: none !important;
    }

    path {
      stroke: #00b89f !important;
      fill: none !important;
    }
  }
}