.nhub-scroll {
    height: 80%;
    overflow-y: scroll;
}

.box-nhub {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.text-green {
    color: #00b89f;
    font-size: 1.2rem;
}