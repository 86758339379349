.device_box {
    padding: 2rem;

    p {
        color: #AFAFAF;
    }

    h1 {
        color: #AFAFAF;
        font-size: 2.5rem;
    }

    .profile_box {
        margin-top: 1.2rem;
        border-bottom: .2rem solid #44484D;
    }

    .profile {
        display: flex;
        align-items: center;
        width: max-content;
        gap: 20px;
        border-bottom: 3px solid #00B89F;
        padding: 0 .8rem 1.2rem;
        cursor: pointer;

        p {
            font-size: 1.5rem;
            color: #AFAFAF;
        }

    }

    .sensor_box {
        margin: 2rem 0 0;
        display: flex;
        gap: 5rem;
    }

    .sensor_box_paper {
        width: 45%;
        border-radius: 10px;
        background-color: #262626;
        box-shadow: 0 0 6px 0 #0000003b;
        padding: 1.5rem;

    }

    .fall_title {
        text-align: center;
        color: #AFAFAF;
        font-size: 1.3rem;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #44484D;
    }

    .connected_sensor {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #44484D;
        margin-bottom: 10px;
    }

    .sensor_title {
        border: none;
        padding-bottom: 0;
        color: #00B89F;
    }

    .refresh {
        cursor: pointer;
        color: #AFAFAF;
        position: relative;
        right: 1rem;
    }

    .label {
        color: white;
        padding: 10px 0;
    }

    .sensor_form {
        width: 100%;
        background: none;
        margin: 0;

        input {}
    }

    .error {
        margin: 0;
        font-size: .9rem;
        color: #d14d4d;
    }

    .btn_box {
        margin: .7rem 0;
        display: flex;
        justify-content: center;

        button {
            background: #00B89F;
            color: white;
            font-size: 1rem;
            font-weight: 500;
            padding: .5rem 2rem;

            &:hover {
                background-color: #019682;
            }
        }

    }

    .no_device_connected {
        color: #d14d4d;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .accordion {
        background-color: #1a1a1a;
        border-radius: 10px;
        padding: 0;
    }

    .accordion_summary {
        border-radius: 10px;
        padding: 20px;
        height: 60px;
        min-height: unset;
        margin-top: 1rem;
    }

    .accordion_active {
        border: 2px solid #00B89F;
    }

    .svg_active {
        color: #00B89F;
    }

    .svg_default {
        color: #AFAFAF;
    }

    .forget_deive {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #d14d4d;
        border-radius: 8px;
        padding: 10px 15px;
        margin-top: .7rem;
        color: #d14d4d;
        cursor: pointer;

        p {
            color: #d14d4d;
        }
    }
}

// @media screen and (max-width: 992px) and (min-width: 576px) {}
@media screen and (max-width: 992px) {

    .device_box {
        .sensor_box {
            flex-direction: column;
            gap: 3rem;
        }

        .sensor_box_paper {
            width: 100%;
        }
    }

}