.wrap {
    overflow-y: scroll;
    height: 100%;
    margin-top: 2rem;

    @media screen and (min-width: 992px) {
        height: 95%;
        overflow-y: scroll;
        padding-bottom: 100px;
    }
}

.svg-icon {
    path {
        fill: #9c9c9c;
        stroke: #9c9c9c;
    }
}

.svg-icon-centric {
    path {
        fill: #00b89f;
        stroke: #00b89f;
    }
}

.svg-icon-fill {
    path {
        fill: none;
        stroke: #9c9c9c;
    }

    rect {
        fill: #9c9c9c;
    }
}

.active-icons-fill {
    path {
        fill: none;
        stroke: #00b89f;
    }

    rect {
        fill: #00b89f;
    }
}

// .active {
//     path {
//         fill: #00b89f;
//         stroke: #00b89f;
//     }
// }

.conditional-upper-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.radio-group {
    width: 100%;
}

.radio-group-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.tab-three-main {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    justify-content: space-evenly;
}

.search-bar-box {
    margin: 1rem 1rem -0.6rem 0;
    position: relative;
    min-width: 250px;
}

.radio-group-center {
    display: flex;
    justify-content: center;
}

.no-search-bar {
    display: none;
}

.label-choose-device {

    .MuiTypography-root {
        font-size: 0.9rem;
        color: #9c9c9c;
    }
}

.icon-display {
    padding: 0.7rem 0.4rem;
    height: 3rem;
    width: 3rem;
}

.icon-display-active {
    padding: 0.7rem 0.4rem;
}

.icon-display-new:active {
    transform: scale(0.80);
    transition: 0.2s;
}

.watch {
    width: 1.6rem;
    height: 1.3rem;
}

.default-icon {
    svg {
        path {
            fill: #00b89f !important;
        }
    }
}

.active-watch-icon {
    svg {
        path {
            fill: white;
            stroke: white;
        }
    }
}

.notification-heading {
    width: 100%;
    margin: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .MuiTypography-root {
        font-size: 0.9rem;
    }
}

.notifications-box-second {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0 0 0;
}

.notification-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.notification-content-first {
    width: 100% !important;

    @media screen and (min-width: 768px) {
        width: 30% !important;
    }
}

.notification-content-second {
    width: 100% !important;

    @media screen and (min-width: 768px) {
        width: 60% !important;
    }
}

// .add-threshold-icon {
//     width: 0.6rem;
//     height: 0.6rem;
// }

.button-add-new {
    width: 8rem;
    margin-bottom: 0 !important;

    svg {
        width: 0.8rem;
        height: 0.8rem;

        path {
            fill: white;
        }
    }

    .MuiTypography-root {
        font-size: 0.8rem;
        color: white;
    }
}

.notification-sensors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem 0;
    flex-wrap: wrap;
}

.box-middle {
    width: 100%;

    .MuiTypography-root {
        font-size: 0.8rem;
    }
}

.no-notifications {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;

    .MuiTypography-root {
        font-size: 0.8rem;
        color: #9c9c9c;
    }
}

.paper-configured {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    padding: 0.5rem 1rem !important;
    border-radius: 0 !important;
}

.pill-paper-configured {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    padding: 0.5rem 1rem !important;
    border-radius: 0 !important;
}

.box-configured-first {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.close-red {
    width: 1rem;
    height: 1rem;

    path {
        fill: red;
    }
}

.gt-lt {
    display: flex;
    flex-direction: column;
}

.box-configured-second {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    align-items: center;
}

.box-configured-third {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.box-configured-third-pill-door {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    max-height: 1rem;
}

.icon-svg-watch {
    path {
        stroke: #00b89f;
    }
}

.primary-icon-stroke {
    path {
        stroke: #00b89f;
    }
}

.primary-stroke-active {
    path {
        stroke: white;
    }
}

.primary-icon-fill {
    path {
        fill: #00b89f;
    }
}

.primary-fill-active {
    path {
        fill: white;
    }
}

.primary-icon-spcl {
    #Path_453 {
        fill: #00b89f !important;
    }
}

.primary-spcl-active {
    #Path_453 {
        fill: white !important;
    }
}

.active-icons {
    path {
        fill: #00b89f;
        stroke: #00b89f;
    }
}

.paper-dropdown {
    padding: 0.5rem !important;
    position: absolute;
    top: 2.8rem;
    left: 0;
    right: 0;
    z-index: 1;
    height: 14rem;
    overflow-y: scroll;
}

.avatar-senior {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
}

.dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem;
}

.dropdown-item:hover {
    cursor: pointer;
    background-color: #252c34;
}

.invisible {
    display: none;
}

.dropdown-text-primary {
    font-size: 1rem !important;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-text-secondary {
    font-size: 0.6rem !important;
}

.box-selected-seniors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    margin: 0.78rem 0;
}

.active-spcl {
    #Path_453 {
        stroke: #00b89f;
    }
}

.active-belt {
    path {
        stroke: #00b89f;
    }
}

.icon-display-new {
    margin: 0 0.5rem !important;
    padding: 0.7rem 0.4rem;
}

.icon-display-active-new {
    margin: 0 0.5rem !important;
    padding: 0.7rem 0.4rem;
}

.icon-display-active-new-centric {
    margin: 0 0.5rem !important;
    padding: 0.6rem 0.4rem;
    border: 2px solid #00b89f;
}

.box-choose-device {
    margin: 1rem 0 0 0;
}

.choose-device-text {
    text-align: center;
}

.box-pill-message {
    display: flex;
    flex-direction: column;
}

.w-100 {
    width: 100%;
}

.add-new {
    width: 7rem;
    display: flex;
    align-items: center;
    border-radius: 0 !important;

    svg {
        margin-right: 0.313rem;
    }
}

.default-icon {
    .MuiTypography-root {
        color: #fff !important;
    }
}

.space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.d-flex {
    display: flex;
    margin: 1rem 0 0 0;
}

.d-none {
    display: none;
}

.box-message {
    max-width: 15rem;

    .MuiTypography-root {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.stack-row {
    display: flex;
    flex-direction: row !important;
    width: 95%;

    .box-row {
        margin-right: 1rem;
    }
}

.active-diff-pill {
    path {
        fill: #00b89f;
    }
}

.box-member-tag {
    cursor: pointer;

    &:active {
        transform: scale(0.90);
    }
}

.box-scroll {
    padding-bottom: 1rem;
}

::-webkit-scrollbar {
    display: block !important;
    height: 0.2rem !important;
    cursor: pointer !important;
}

// ::-webkit-scrollbar-thumb {
//     background-color: #00B89F !important;
// }