.senior-info {
    background-color: transparent !important;

    .MuiDialog-paper {
        padding: 0 !important;
    }
}

.senior-info-card {
    padding: 0 !important;
}

.user-icon {
    min-width: 2.75rem;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    object-fit: cover;
}

.tab-label {
    position: relative;
}
.tab-border {
    border-bottom: 2px solid #00B89F;
}
.info-icon {
    width: 1.2rem;
}

.user-profile {
    background: url('../../../assets/background.png') no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 1rem;

    position: relative;

    .user-image {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        object-fit: cover;
    }

    .close-icon {
        position: absolute;
        width: 1.5rem;
        top: 0.5rem;
        right: 0.5rem;
    }

    .cursor {
        cursor: pointer;
    }
}

.user-details {
    background-color: #00B89F;
    padding: 1rem 2rem;
}

.user-measurements {
    justify-content: space-evenly;
    padding: 1rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon {
    width: 0.9rem;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.span-location {
    display: flex;
    align-items: center;

    .location-custom {
        margin-right: 0.5rem;
    }
}

.d-flex {
    display: flex;
    margin: 0;
}

.txt-center {
    text-align: center;
}