.input-adornment .MuiFormControl-root {
    margin-bottom: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    height: 0 !important;

    svg {
        width: 15px !important;
    }
}

.add-senior-input .MuiInputBase-root.MuiFilledInput-root {
    padding-right: 0 !important;
}

.input-adornment:focus {
    outline: transparent !important;

}

.input-adornment {
    font-size: 10px;

    .MuiSelect-select {
        width: 2rem;
    }

    .MuiFormControl-root {
        .MuiInputBase-root {
            width: 3.1rem;
            background-color: transparent !important;
            &:hover {
                background-color: transparent !important;
            }
            &:active {
                background-color: transparent !important;
            }
            &:focus {
                background-color: transparent !important;
            }
        }
    }

    .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input:focus {
        outline: none !important;
        background-color: transparent !important;
    }

    .MuiInputBase-root {
        .MuiSelect-select {
            padding: 1px 20px 0 0 !important;
        }
    }

    .MuiInputBase-root.MuiFilledInput-root.Mui-focused {
        outline: none !important;
    }

    .MuiSelect-nativeInput {
        width: 2rem;
    }

    input {
        position: absolute;
        right: 0px;
    }
}