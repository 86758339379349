.email-dialog {
    max-width: 37.5rem;
    margin: 0 auto;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.dialog-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}