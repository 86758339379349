.dropdown div {
    padding: 0.325rem;
}

.allDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
    }
}

.disabled {
    background-color: #016659 !important;
    color: white !important;
}

.Mui-error fieldset {
    border: none;
}

.avatar {
    width: 7rem;
    height: 6rem;
}

.stack {
    width: 100%;
    overflow-y: scroll;
}

.parent-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.parent-box-box1 {
    border: 2px solid;
    border-radius: 1rem;
    border-color: #00b89f;
    margin-bottom: 1rem;
    width: 75%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}