.span {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 4px;
}

.menu-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.active-profile, .active-profile-box {
    color: #00b89f;
    path {
        fill: #00b89f;
    }
}

.active-addnew, .active-addnew-box {
    color: #00b89f;
    path {
        fill: #00b89f;
    }
}

.breadcrumbs .MuiTypography-root {
    font-size: 1.75rem !important;

    @media screen and (max-width: 760px) {
        font-size: 1.5rem !important;
    }

    @media screen and (max-width: 425px) {
        font-size: 0.9rem !important;
    }
}

.title-typography {
    font-size: 1.75rem !important;

    @media screen and (max-width: 768px) {
        margin-top: 1rem !important;
        font-size: 1.5rem !important;
    }
}

.box-parent-dash {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: flex-end;
    }
}

.icons-box {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.5rem 0;
    }
}

.dash-stack {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 0.5rem 0;
    }
}

.search-box-box {
    width: 22rem;

    .MuiFormControl-root {
        width: 22rem;

        @media screen and (max-width: 768px) {
            width: 15rem;
        }

        @media screen and (max-width: 425px) {
            width: 10rem;
        }
    }

    @media screen and (max-width: 768px) {
        width: 15rem;
    }

    @media screen and (max-width: 425px) {
        width: 10rem;
    }
}