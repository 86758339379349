.accounts-scroll {
    height: 86% !important;
    overflow-y: scroll !important;
}

.box-accounts {
    height: 60%;
}

.typography-accounts {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.text-green {
    color: #00b89f;
}

.nav-link {
    width: 100%;
}

.btn-accounts {
    margin-right: 3rem !important;

    @media screen and (max-width: 400px) {
        margin-right: 0 !important;
    }
}