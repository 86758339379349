.sendOtp {
  margin: 1rem 0 1.5rem;
  > input {
    border-radius: 5px;
    border: 0px solid;
    // font-size: 5rem;
    // font-weight: 800;
    &:focus {
      outline: 2px solid #00b89f;
    }
  }
}
.resendBtn {
  color: #ccc;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    color: #aaa;
  }
}
