.item-dropdown div {
    padding: 0 auto;
}

// .grid-item {
//     padding-top: 0 !important;
// }

// .item {
//     padding-top: 8px !important;
// }

// .rltn {
//     padding-top: 1rem !important;
// }

.dropdown {

}

.executive-grid {
    padding: 0.5rem 0 0.5rem 0.5rem;
}

.executive-grid-item { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 20rem;
}

.permissions-typography {
    font-size: 1rem;
    color: #9c9c9c;
    width: fit-content;
    margin: 0 0 0 0.5rem !important;
}

.permissions-grid {
    width: 70%;
    padding: 0.5rem 0 0.5rem 0.5rem;
}

.permissions-grid-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Mui-error fieldset {
    border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}