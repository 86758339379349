.search-width {
    font-size: 1rem;

    @media screen and (max-width: 425px) {
        font-size: 0.8rem;
    }
}

.search-icon {
    height: 1rem;
    width: 1rem;

    @media screen and (max-width: 425px) {
        height: 0.8rem;
        width: 0.8rem;
    }
}