.header {
  position: relative;
  // left: 0;
  right: 1.8rem;
  margin-bottom: 0.5rem;
  > button {
    position: absolute;
    top: 0;
    left: 0;
  }
}


.sendOTPButton {
  font-weight: 800;
}

.inputfield {
  p { 
    color: red !important;
  }
}