.box {
    width: "100%";
}

.box-active {
    @media screen and (min-width: 960px) {
    max-width: 78%;
    }
}

.paper-users {
    min-width: 20%;
}