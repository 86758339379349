.addSeniorInput {
  label {
    display: block;
  }
  input {
    display: block; 
    font-size: 1rem;
    width: 100%;
    border: 0px solid;
    border-radius: 0.5rem;
    padding: 0.75rem;
    &:focus {
      outline: 2px solid #00b89f;
    }
  }

}
