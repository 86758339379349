.select-mui {
    border: transparent !important;
    outline: transparent !important;
    svg {
        fill: #d8d8d8;
    }
}

.reports-mui {
    border: none !important;
}

.Notification {
    min-width: 4.5rem !important;
}

.Notification div div {
    padding-bottom: 0 !important;
}

.Reports {

}

// .css-17ugeg-MuiSelect-select-MuiInputBase-input-MuiInput-input {
//     padding-bottom: 0 !important;
// }