.Mui-error {
  asterisk {
    color: "#db3131",
    "&$error" {
      color: "#db3131",
    }
  }
  color: black !important;
}

.MuiClockPicker-root {
  svg {
    path {
      fill: #fff;
    }
  }
  .MuiTypography-root {
    color: #fff;
  }
}
