.patch-dialog-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.w-75 {
    width: 75%
}

.mb-1 {
    margin-bottom: 1rem;
}