.member-info {
    background-color: transparent !important;

    .MuiDialog-paper {
        padding: 0 !important;
    }
}

.member-info-card {
    padding: 0 !important;
}

.user-icon {
    min-width: 2.75rem;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    object-fit: cover;
}

.tab-label {
    position: relative;
}

.tab-border {
    border-bottom: 2px solid #00B89F;
}

.info-icon {
    width: 1.2rem;
}

.user-profile {
    background: url('../../../assets/background.png') no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 1rem;

    position: relative;

    .user-image {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        object-fit: cover;
    }

    .close-icon {
        position: absolute;
        width: 1.5rem;
        top: 0.5rem;
        right: 0.5rem;
    }

    .cursor {
        cursor: pointer;
    }
}

.user-details {
    background-color: #00B89F;
    padding: 1rem 2rem;
}

.user-measurements {
    justify-content: space-evenly;
    padding: 1rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon {
    width: 0.9rem;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.span-location {
    display: flex;
    align-items: center;

    .location-custom {
        margin-right: 0.5rem;
    }
}

.d-flex {
    display: flex;
    margin: 0;
}

.executive-div {
    position: relative;
    width: fit-content;
    margin: 1rem auto;

    .executive-svg {
        position: absolute;
        bottom: 0.2rem;
        right: 0.2rem;
        width: 2.5rem;
        height: 2.5rem;
    }
}

.div-permissions {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
    }
}

.parent-permissions-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 5rem;

    @media screen and (max-width: 425px) {
        height: 11rem;
    }

    @media screen and (max-width: 320px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

.active-permission-btn {
    border-color: #00B89F;
    color: #00B89F;
}

.normal-btn {
    border-color: #9C9C9C !important;
    color: #9C9C9C;
}

.permissions-btn {
    padding: 0.5rem 1rem;
    border: solid #00B89F 1px;
    border-radius: 1rem;
    font-size: 0.8rem
}

.chat-btn {
    margin: auto 1rem;

    @media screen and (max-width: 425px) {
        margin: 1rem auto;
    }

    @media screen and (max-width: 320px) {
        margin: 0.5rem auto;
    }
}