.nhub-dialog {
    margin: 0 auto;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.dialog-div-nhub {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .MuiPaper-root.MuiDialog-paper {
        min-width: 100% !important;
        width: 100% !important;
    }
}

.box-btns-dialog {
    width: 100%;
}

.dialog-text {
    font-size: 0.8rem !important;
}