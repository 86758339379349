@media screen and (max-width: 599px) {
    .card-paper {
        min-width: 100%;
    }
}

@media screen and (max-width: 1368px) {
    .grid-item {
        max-width: 10rem;
    }
}

.card-paper {
    cursor: pointer;
}