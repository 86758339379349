.green {
    path {
        fill: #00b89f;
    }
}

.parent-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parent-box-box1 {
    max-width: 10rem;

    @media screen and (max-width: 768px) {
        margin-top: 1rem;
    }
}

.box-spn {
    width: 100%;
    padding: 0.375rem 0.5rem;
}

.grid-senior {
    .grid-senior-item {
        height: 4.2rem;
        margin-bottom: 8px;
    }

    @media screen and (min-width: 1200px) {
        .grid-senior-item {
            height: 4.2rem;
        }
    }

    @media screen and (min-width: 768px) {
        .grid-senior-item {
            height: 4.2rem;
        }
    }

    @media screen and (min-width: 425px) {
        .grid-senior-item {
            height: 4.2rem;
        }
    }

    @media screen and (min-width: 320px) {
        .grid-senior-item {
            height: 4.2rem;
        }
    }
}

.center-grid-item {
    display: flex;
    justify-content: center;
}