.active-accounts {
    width: 100% !important;
    padding: 1rem 2rem !important;
    border-radius: 0.75rem;
    height: 100%;
}

.active-accounts-box {
    width: 100% !important;
}

.active-accounts-stack {
    width: 100% !important;
    &.acc {
        flex-direction: row;
        @media screen and (max-width:767px) {
            flex-direction: column;
        }
    }
}

.active-accounts-stack-box {
    width: 20% !important;
    @media screen and (max-width:767px) {
        width: 100% !important;
    }
}

.active-accounts-stack-stack {
    width: 80% !important;
    @media screen and (max-width:767px) {
        width: 100% !important;
        margin-bottom: 1rem;
    }
}

.active-accounts-stack-stack-box {
    width: 32% !important;
    margin: 0 !important;
}

.box2 {
    padding: 0 2% !important;
}

.active-accounts-stack-stack-box{
    div{
        margin: 0 !important;
    }
}

.autocomplete {
    border-radius: 2rem;
    fieldset {
        border-color: #d8d8d8;
    }
    svg {
        color: #d8d8d8
    }
    input {
        color: #d8d8d8 !important;
    }
}

.autocomplete .MuiAutocomplete-inputFocused {
    color: #d8d8d8;
}