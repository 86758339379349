@media screen and (max-width: 599px) {
    .grid-item {
        min-width: 100%;
    }
}

@media screen and (max-width: 1368px) {
    .grid-item {
        max-width: 10rem;
    }
}

.user-roles {
    overflow-y: scroll;
    height: 81%;
}

.no-users {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60%;
}

.text-green {
    color: #00b89f;
}