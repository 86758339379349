.stack-reports {
  width: 90%;
  @media screen and (min-width: 425px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 960px) {
    width: 100%;
  }
  @media screen and (min-width: 1200px) {
    width: 90%;
  }
}

.recharts-tooltip-wrapper.recharts-tooltip-wrapper-right.recharts-tooltip-wrapper-top {
  outline: none !important;
  border: none !important;
  &:focus-visible {
    outline: none !important;
  }
  &:hover {
    border: none !important;
  }
}
path.recharts-rectangle.recharts-tooltip-cursor {
  fill: #303b47a1 !important;
}

.recharts-default-tooltip {
  background-color: #303b47 !important;
  border: 1px solid #cccccc75 !important;
  border-radius: 0.8rem !important;
  outline: none !important;
}
.stack-reports .stack-dropdown-container {
  padding: 10px 22px 0 40px !important;
  justify-content: flex-end !important;
  .Reports {
    width: max-content !important;
    height: fit-content !important;
    margin-bottom: 1rem !important;
    div {
      font-weight: 400;
      font-size: 0.6rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .stack-reports .stack-dropdown-container {
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }
}
@media screen and (max-width: 1800px) {
  // .stack-reports .stack-dropdown-container .Reports div {
  //     // font-size: 1rem;
  // }
}
