.account-details-stack {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    // align-items: stretch;
    align-content: stretch;
    padding: 1rem 2rem;
    font-size: 0.8rem !important;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 92%;

    @media screen and (min-width: 1600px) {
        font-size: 0.5rem !important;
    }
}

.account-details-stack-paper1 {
    width: 65%;
}

.account-details-stack-paper2 {
    width: 33%;
    margin-top: 0 !important;
}

.inside-stack {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
}

.stack-box1 {
    width: 40%;
}

.green {
    path {
        fill: #00b89f;
    }
}

.greenFill {
    path {
        fill: none;
    }
}

.gray {
    path {
        stroke: #9c9c9c;
        fill: #9c9c9c;
    }
}

.grey {
    path {
        stroke: #9c9c9c;
        // fill: #9c9c9c;
    }
}

.beltgrey {
    path {
        fill: #9c9c9c;
        stroke: none;
    }
}
.beltgreenFill {
    path {
        fill: #00b89f;
    }
    
}
.watch {
    height: 23px;
}

.second-box-stack {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-green {
    color: #00b89f;
}

.camera-stack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.watch-stack {
    min-height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.stack-box1, .stack-box2 {
    min-width: 45%;
    width: 100%;
}

.nhub-box {
    min-height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.div-box2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.box-sensors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    height: 5rem;
    width: 100%;
}

.box-executive-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.box-member-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.box-member-image {
    display: flex;
    align-items: center;
}

.stack-no-member {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 5rem;
}

.delete-icon {
    color: red;
    width: 1rem;
    height: 1rem;
}

.edit-icon {
    width: 1rem;
    height: 1rem;
}

.align-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 0 !important;
}

.typography-font {
    font-size: 0.6rem !important;
    color: rgb(179, 2, 2);
}

.box-nhub-inactive {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 10rem;

    .MuiTypography-root {
        font-size: 0.6rem;
    }
}

.btn-connect-nhub.Mui-disabled {
    color: #4D4C4C !important;
    border: 1px solid #4D4C4C !important;
}