.executive-dialog-box {
    .MuiDialog-paper {
        max-width: 100% !important;
        width: 80% !important;
    }
}

.box-profile {
    display: flex;
    justify-content: center;
}

.box-profile-box1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}