.active {
    path {
        fill: #d8d8d8;
    }
}

.dialog-watch {
    .MuiDialog-paper {
        max-width: 100% !important;
        width: fit-content;
        margin: 0 auto;
        padding: 1.5rem;
    }
}