.table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    // max-height: 64%;
    overflow-y: hidden;
    margin-bottom: 1rem;
}

.rpi-updates {
    height: 100%;
}

// .table-mac {
//     // border: solid 1px #d8d8d8;
//     height: inherit;
// }

// .table-mac,
// .table-mac-cell {
//     border-color: #d8d8d8;
// }

.table-body {
    height: 70%;
    overflow-y: scroll;
}

.table-scroll {
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 0.5rem;
    background-color: #22282e;
}

.nodevicemsg{
    display: flex;
    align-items: center;
    justify-content: center;
}

.a {
    text-decoration: underline;
    color: white;
}

.a:visited {
    color: #00b89f;
}

.tr {

    .th {
        background-color: #22282e;
        color: #00B89F !important;
        text-align: center;
    }

    .td {
        background-color: #22282e;
    }

    .table-mac-cell {
        border-bottom: none !important;
    }

    //solid 0.1rem #6e6e6e

    &:last-child {
        .td {
            border-bottom: none !important;
        }
    }

    // &:nth-child(even) {
    //     background-color: #252b32;
    // }
}

.header-box {
    margin-bottom: 1.5rem;
}

.header-updates-box {
    padding: 0 1rem;
}

.bb-0 {
    border-bottom: 0 !important;
}

.mt-1 {
    margin-top: 1rem;
}

.mx-1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.px-1 {
    padding: 0 1rem;
}

.d-flex {
    display: flex !important;
}

.flex-row {
    flex-direction: row !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.justify-content-start {
    justify-content: start !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.align-items-center {
    align-items: center !important;
}

.stack-first{
    max-height: 26rem;
}
.refresh {
    margin: 0 0.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.refresh-loader {
    font-size: 2em;
    text-align: right;
    color: #afafaf;
    font-weight: bold;
    animation: animate 2s ease-in-out infinite;
    color: #00B89F;
}

.pointer{
    cursor: pointer;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(80deg);
    }

    50% {
        transform: rotate(160deg);
    }

    75% {
        transform: rotate(240deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.w-xs-100 {
    @media screen and (max-width: 767px) {
        width: 100% !important;
    }
}