.close-button {
    width: 1rem;
    height: 1rem;
    path {
        fill: #9c9c9c;
    }
}

.text-green {
    color: #00b89f;
}

.custom-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem !important;
}