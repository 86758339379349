.svg-styles {
    path {
        fill: #00b89f;
    }
    width: 1.8rem;
    height: 1.8rem;
}
.svg-styles-fill {
    path {
        fill: none;
    }
    width: 1.8rem;
    height: 1.8rem;
}

.sensor-box {
    display: flex;
    flex-direction: row;
    border: 2px solid #E5E5E55E;
    border-radius: 0.5rem;
    padding: 0.75rem;
    align-items: center;
    margin: 0 10px 10px 0;
    min-width: fit-content;
}

.btn-connect-nhub.Mui-disabled {
    color: #4D4C4C !important;
    border: 1px solid #4D4C4C !important;
}